<template>
  <v-container>
    <v-dialog
      persistent
      ref="addPhotoDialog"
      v-model="addPhotoDialog"
      width="500"
    >
      <AddPhotoDialog
        :customer="customer"
        :site_id="site_id"
        :projectId="projectId"
        @close-dialog="addPhotoDialog = false"
      ></AddPhotoDialog>
    </v-dialog>
    <v-dialog
      persistent
      ref="editPhotoDialog"
      v-model="editPhotoDialog"
      width="500"
    >
      <EditPhotoDialog
        :customer="customer"
        :site_id="site_id"
        :photo="photo"
        @close-dialog="editPhotoDialog = false"
      ></EditPhotoDialog>
    </v-dialog>
    <v-dialog ref="showPhotoDialog" v-model="showPhotoDialog" width="70%">
      <ShowPhotoDialog
        :customer="customer"
        :site_id="site_id"
        :photo="photo"
        @close-dialog="showPhotoDialog = false"
      ></ShowPhotoDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <Table
          :loading="photoLoading"
          :headers="headers"
          :items="photos"
          :total-records="photoPagination.records_total || 0"
          :number-of-pages="photoPagination.total_pages || 1"
          :footer="false"
          class="font-weight-medium mb-4"
          @pagination:update="updateCustomerPhotos"
        >
          <template v-slot:item.photo="{ item }">
            <div class="py-2 px-2 text-center">
              <v-img
                @click="showPhoto(item.id)"
                class="img-photo-list"
                :src="item.path ? item.path_url : `${root}images/no-image.jpg`"
                width="200"
              ></v-img>
            </div>
          </template>
          
          <template v-slot:item.memo="{item}">
          <span class="white-space-preline">{{item.memo}}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn @click="editPhoto(item.id)" text color="#336ABB">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.contributor="{ item }">
            {{ item.updated_by_user ? item.updated_by_user.full_name : '-' }}
          </template>

          <template v-slot:top>
            <div class="mb-6">
              <v-row align="end">
                <v-col cols="auto" class="text-heading-1">
                  物件に関連する画像一覧
                </v-col>
                <v-col cols="auto" class="ml-4 text-heading-3 ">
                  全 {{ photosCount }} 件 あります。
                </v-col>
                <v-col cols="auto" class="flex-grow-1 text-right">
                  <v-btn
                    height="28"
                    class="btn-new-task"
                    color="#CFD3FE"
                    depressed
                    min-width="100"
                    @click="addPhoto"
                  >
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    画像
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </Table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import AddPhotoDialog from '@/components/admin/partials/Customer/View/Photos/Dialog'
import EditPhotoDialog from '@/components/admin/partials/Customer/View/Photos/EditDialog'
import ShowPhotoDialog from '@/components/admin/partials/Customer/View/Photos/ShowPhotoDialog'
import { mapGetters } from 'vuex'
export default {
  name: 'Photos',
  components: {
    AddPhotoDialog,
    EditPhotoDialog,
    Table,
    ShowPhotoDialog
  },
  props: {
    customer: {
      type: Object
    },
    projectId: {
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters(['photos', 'photoPagination', 'photosCount', 'photoLoading']),
    site_id() {
      return this.$route.params.site_id
    },
    indexStart() {
      return this.photoPagination.records_from - 1
    }
  },
  mounted() {
    this.updateCustomerPhotos(1)
  },
  data() {
    return {
      addPhotoDialog: false,
      editPhotoDialog: false,
      showPhotoDialog: false,
      photo: 1,
      page: 1,
      root: process.env.VUE_APP_BASEURL,
      pageCount: 5,
      headers: [
        { text: '画像', align: 'center', value: 'photo', width: 100 },
        {
          text: '日付',
          align: 'center',
          value: 'date',
          width: 200,
          sortable: false
        },
        {
          text: 'メモ',
          align: 'center',
          value: 'memo',
          width: 400,
          sortable: false
        },
        {
          text: '投稿者',
          align: 'center',
          value: 'contributor',
          width: 200,
          sortable: false
        },
        {
          text: 'アクション',
          align: 'center',
          value: 'actions',
          width: 150,
          sortable: false
        }
      ]
    }
  },
  methods: {
    addPhoto() {
      this.addPhotoDialog = true
    },
    editPhoto(id) {
      this.photo = this.photos.find(c => c.id == id)
      this.editPhotoDialog = true
    },
    showPhoto(id) {
      this.photo = this.photos.find(c => c.id == id)
      this.showPhotoDialog = true
    },
    updateCustomerPhotos(page) {
      this.page = page
      let param = {
        customer_id: this.$route.params?.customer_id,
        site_id: this.$route.params?.site_id,
        project_id: this.projectId,
        paginate: 10,
        page: page
      }
      this.$store.dispatch('GET_ALL_PHOTO_BY_CUSTOMER_ID', param)
    }
  }
}
</script>

<style lang="scss" src="./Photos.scss" scoped></style>
