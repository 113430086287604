<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-1">
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="$emit('close-dialog')"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="text-center pb-0">
            <img
              :src="preview_file"
              width="100%"
              height="100%"
              class="image_preview mb-2"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'ShowPhotoDialog',
  data() {
    return {
      descriptionMaxLength: 500,
      modal: {
        date: false
      },
      preview: null,
      loading: false
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    photo: {
      type: Object,
      required: true
    },
    site_id: {
      type: Number,
      required: true
    }
  },
  computed: {
    preview_file: {
      get() {
        if (this.preview) return this.preview

        if (!this.data.path) {
          return `${process.env.VUE_APP_BASEURL}images/no-image.jpg`
        }

        return this.data.path_url
      },
      set(value) {
        this.preview = value
      }
    },
    data() {
      return JSON.parse(JSON.stringify(this.photo))
    }
  },
  methods: {
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true
        let id = this.data.id
        let data = new FormData()
        data.append('customer_id', this.customer.id)
        data.append('site_id', this.site_id)
        data.append('_method', 'PUT')
        for (const [key, value] of Object.entries(this.data)) {
          data.append(key, value ? value : value == 0 ? 0 : '')
        }

        this.$store
          .dispatch('UPDATE_PHOTO', { id, data })
          .then(
            response => {
              if (response.data.status == 'success') {
                let param = {
                  photo: {
                    id: this.customer.id,
                    site_id: this.site_id,
                    paginate: 10
                  }
                }
                this.$store.dispatch(
                  'GET_ALL_PHOTO_BY_CUSTOMER_ID',
                  param.photo
                )
                this.$emit('close-dialog')
              }
            },
            error => {
              this.$swal.fire({
                title: 'システムエラー',
                text: '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
                icon: 'error',
                confirmButtonText: '閉じる'
              })
              throw error
            }
          )
          .finally(() => (this.loading = false))
      })
    },
    remove() {
      let id = this.data.id
      this.loading = true

      this.$store
        .dispatch('DELETE_PHOTO', id)
        .then(
          response => {
            if (response.data.status == 'success') {
              let param = {
                photo: {
                  id: this.customer.id,
                  site_id: this.site_id,
                  paginate: 10
                }
              }
              this.$store.dispatch('GET_ALL_PHOTO_BY_CUSTOMER_ID', param.photo)
              this.$emit('close-dialog')
            }
          },
          error => {
            this.$swal.fire({
              title: 'システムエラー',
              text: '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
              icon: 'error',
              confirmButtonText: '閉じる'
            })
            throw error
          }
        )
        .finally(() => (this.loading = false))
    },
    onFilePicked(e) {
      this.data.photo = e.target.files[0]
      this.preview_file = URL.createObjectURL(this.data.photo)
    },
    formatDate(e) {
      return dayjs(e).format('D')
    }
  }
}
</script>

<style lang="scss" src="./ShowPhotoDialog.scss" scoped></style>
