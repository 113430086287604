<template>
  <validation-observer ref="observer">
    <form @submit.prevent="save">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row class="py-4">
              <v-col cols="12" class="text-center text-heading-1">
                写真管理
                <div>
                  <v-btn
                    depressed
                    class="close-button"
                    fab
                    color="#E27C7C"
                    width="20"
                    height="20"
                    @click="$emit('close-dialog')"
                  >
                    <v-icon size="14">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12" class="text-center pb-0">
                <input
                  type="file"
                  ref="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="onFilePicked"
                />

                <img
                  :src="preview_file"
                  width="100%"
                  height="300"
                  class="image_preview mb-2"
                />
                <!-- <v-btn height="200" block depressed @click="$refs.file.click()">
                  <v-icon v-if="!preview_file" size="180">$imagePlaceholder</v-icon>
                </v-btn> -->
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-btn color="#AD4545" @click="removeFile()" text>
                      - 写真削除
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn color="#336ABB" @click="$refs.file.click()" text>
                      + 画像アップロード
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="form-header mb-2">
                  実施日
                </div>
                <div>
                  <v-dialog
                    ref="dialogDate"
                    v-model="modal.date"
                    :return-value.sync="data.date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="date"
                        rules="required"
                      >
                        <v-text-field
                          v-model="data.date"
                          readonly
                          class="form-text"
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          placeholder="日付選択"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          :hide-details="errors.length === 0"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      :day-format="formatDate"
                      v-model="data.date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.date = false">
                        キャンセル
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogDate.save(data.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-header mb-2">
                  説明文
                </div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="memo"
                    rules="required"
                  >
                    <v-textarea
                      class="form-text"
                      dense
                      outlined
                      placeholder="画像の説明文"
                      v-model="data.memo"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :hide-details="errors.length === 0"
                    ></v-textarea>
                  </validation-provider>
                  <div class="text-right mt-1">
                    {{ data.memo.length }} / {{ descriptionMaxLength }} 文字
                  </div>
                </div>
              </v-col>

              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="6">
                    <v-btn @click="remove" color="#AD4545" text>
                      <v-icon left>$trash</v-icon>
                      削除する
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-col cols="auto">
                      <v-btn
                        @click="save"
                        :loading="loading"
                        color="#4F55A7"
                        class="white--text"
                        width="100"
                        >更新する
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </form>
  </validation-observer>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'EditDialog',
  data() {
    return {
      descriptionMaxLength: 500,
      modal: {
        date: false
      },
      preview: null,
      loading: false
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    photo: {
      type: Object,
      required: true
    },
    site_id: {
      type: Number,
      required: true
    }
  },
  computed: {
    preview_file: {
      get() {
        if (this.preview) return this.preview

        if (!this.data.path) {
          return `${process.env.VUE_APP_BASEURL}images/no-image.jpg`
        }

        return this.data.path_url
      },
      set(value) {
        this.preview = value
      }
    },
    data() {
      return JSON.parse(JSON.stringify(this.photo))
    }
  },
  methods: {
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true
        let id = this.data.id
        let data = new FormData()
        data.append('customer_id', this.customer.id)
        data.append('site_id', this.site_id)
        data.append('_method', 'PUT')
        for (const [key, value] of Object.entries(this.data)) {
          data.append(key, value ? value : value == 0 ? 0 : '')
        }

        this.$store
          .dispatch('UPDATE_PHOTO', { id, data })
          .then(
            response => {
              if (response.data.status == 'success') {
                let param = {
                  photo: {
                    customer_id: this.customer.id,
                    site_id: this.site_id,
                    paginate: 10
                  }
                }
                this.$store.dispatch(
                  'GET_ALL_PHOTO_BY_CUSTOMER_ID',
                  param.photo
                )
                this.$emit('close-dialog')
              }
            },
            error => {
              this.$swal.fire({
                title: 'システムエラー',
                text: '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
                icon: 'error',
                confirmButtonText: '閉じる'
              })
              throw error
            }
          )
          .finally(() => (this.loading = false))
      })
    },
    remove() {
      let id = this.data.id
      this.loading = true

      this.$store
        .dispatch('DELETE_PHOTO', id)
        .then(
          response => {
            if (response.data.status == 'success') {
              let param = {
                photo: {
                  customer_id: this.customer.id,
                  site_id: this.site_id,
                  paginate: 10
                }
              }
              this.$store.dispatch('GET_ALL_PHOTO_BY_CUSTOMER_ID', param.photo)
              this.$store.dispatch('CUSTOMER_GET', this.customer.id)
              this.$emit('close-dialog')
            }
          },
          error => {
            this.$swal.fire({
              title: 'システムエラー',
              text: '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
              icon: 'error',
              confirmButtonText: '閉じる'
            })
            throw error
          }
        )
        .finally(() => (this.loading = false))
    },
    onFilePicked(e) {
      this.data.photo = e.target.files[0]
      this.preview_file = URL.createObjectURL(this.data.photo)
    },
    formatDate(e) {
      return dayjs(e).format('D')
    },
    removeFile() {
      this.preview_file = process.env.VUE_APP_BASEURL + 'images/no-image.jpg'
      this.data.remove_photo = true
      this.data.path = null
    }
  }
}
</script>

<style lang="scss" src="./EditDialog.scss" scoped></style>
