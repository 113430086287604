var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-dialog', {
    ref: "addPhotoDialog",
    attrs: {
      "persistent": "",
      "width": "500"
    },
    model: {
      value: _vm.addPhotoDialog,
      callback: function callback($$v) {
        _vm.addPhotoDialog = $$v;
      },
      expression: "addPhotoDialog"
    }
  }, [_c('AddPhotoDialog', {
    attrs: {
      "customer": _vm.customer,
      "site_id": _vm.site_id,
      "projectId": _vm.projectId
    },
    on: {
      "close-dialog": function closeDialog($event) {
        _vm.addPhotoDialog = false;
      }
    }
  })], 1), _c('v-dialog', {
    ref: "editPhotoDialog",
    attrs: {
      "persistent": "",
      "width": "500"
    },
    model: {
      value: _vm.editPhotoDialog,
      callback: function callback($$v) {
        _vm.editPhotoDialog = $$v;
      },
      expression: "editPhotoDialog"
    }
  }, [_c('EditPhotoDialog', {
    attrs: {
      "customer": _vm.customer,
      "site_id": _vm.site_id,
      "photo": _vm.photo
    },
    on: {
      "close-dialog": function closeDialog($event) {
        _vm.editPhotoDialog = false;
      }
    }
  })], 1), _c('v-dialog', {
    ref: "showPhotoDialog",
    attrs: {
      "width": "70%"
    },
    model: {
      value: _vm.showPhotoDialog,
      callback: function callback($$v) {
        _vm.showPhotoDialog = $$v;
      },
      expression: "showPhotoDialog"
    }
  }, [_c('ShowPhotoDialog', {
    attrs: {
      "customer": _vm.customer,
      "site_id": _vm.site_id,
      "photo": _vm.photo
    },
    on: {
      "close-dialog": function closeDialog($event) {
        _vm.showPhotoDialog = false;
      }
    }
  })], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Table', {
    staticClass: "font-weight-medium mb-4",
    attrs: {
      "loading": _vm.photoLoading,
      "headers": _vm.headers,
      "items": _vm.photos,
      "total-records": _vm.photoPagination.records_total || 0,
      "number-of-pages": _vm.photoPagination.total_pages || 1,
      "footer": false
    },
    on: {
      "pagination:update": _vm.updateCustomerPhotos
    },
    scopedSlots: _vm._u([{
      key: "item.photo",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "py-2 px-2 text-center"
        }, [_c('v-img', {
          staticClass: "img-photo-list",
          attrs: {
            "src": item.path ? item.path_url : "".concat(_vm.root, "images/no-image.jpg"),
            "width": "200"
          },
          on: {
            "click": function click($event) {
              return _vm.showPhoto(item.id);
            }
          }
        })], 1)];
      }
    }, {
      key: "item.memo",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "white-space-preline"
        }, [_vm._v(_vm._s(item.memo))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "color": "#336ABB"
          },
          on: {
            "click": function click($event) {
              return _vm.editPhoto(item.id);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }, {
      key: "item.contributor",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.updated_by_user ? item.updated_by_user.full_name : '-') + " ")];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mb-6"
        }, [_c('v-row', {
          attrs: {
            "align": "end"
          }
        }, [_c('v-col', {
          staticClass: "text-heading-1",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 物件に関連する画像一覧 ")]), _c('v-col', {
          staticClass: "ml-4 text-heading-3 ",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 全 " + _vm._s(_vm.photosCount) + " 件 あります。 ")]), _c('v-col', {
          staticClass: "flex-grow-1 text-right",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "btn-new-task",
          attrs: {
            "height": "28",
            "color": "#CFD3FE",
            "depressed": "",
            "min-width": "100"
          },
          on: {
            "click": _vm.addPhoto
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-plus ")]), _vm._v(" 画像 ")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }